export default {
  name: "GroupAdd",
  data: () => ({
    group: {
      name       : undefined,
      description: undefined
    },
    isGroupNameDuplicate: false,
    showAddGroupDialog  : false
  }),
  props: {
    pIsAddingGroup: Boolean,
    pGroupAddError: Object
  },
  methods: {
    addGroup() {
      this.$emit("addGroup", {
        ...this.group
      })
    }
  },
  watch: {
    "group.name": {
      handler: function() {
        if (this.isGroupNameDuplicate) {
          this.$emit("resetGroupAddError")
        }
      }
    },
    pGroupAddError: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue?.field === "name" && newValue?.type === "duplicate") {
          this.isGroupNameDuplicate = true
        } else {
          this.isGroupNameDuplicate = false
        }
      }
    }
  }
}